<script lang="ts">
    import type { Snippet } from 'svelte'
    import type { HTMLAttributes } from 'svelte/elements'

    import type { Element, SectionVariants } from '../types'
    import { concatClasses } from '../utils'

    interface Props extends HTMLAttributes<HTMLDivElement> {
        element?: Element
        variant?: SectionVariants
        children: Snippet
    }

    const {
        class: classNames = '',
        element = 'section',
        variant = 'white',
        children,
        ...rest
    }: Props = $props()

    const classes = $derived(
        concatClasses(['section', `section-${variant}`, classNames])
    )
</script>

<svelte:element this={element} class={classes} {...rest}>
    {@render children()}
</svelte:element>

<style global lang="postcss">
    .section {
        padding: theme('spacing.12') 0;

        @media screen(md) {
            padding: theme('spacing.16') 0;
        }

        @media screen(xl) {
            padding: theme('spacing.20') 0;
        }
    }

    .section-dark {
        background-color: theme('colors.nmd-800');
    }

    .section-light {
        color: theme('colors.gray-600');
        background-color: theme('colors.gray-50');
    }

    .section-white {
        color: theme('colors.nmd-700');
        background-color: theme('colors.white');
    }
</style>
